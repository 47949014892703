<template>
  <div class="vip-layout">
    <v-container class="content">
      <div>
        <h3 class="text-xl">客戶分級</h3>
      </div>

      <div>
        <div class="my-3">請選擇{{ storeName }}的分級</div>
      </div>

      <v-select
        class="select"
        v-model="selectedGroup"
        :items="list"
        item-text="name"
        item-value="id"
        outlined
        return-object
        hide-details>
      </v-select>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn depressed outlined @click="clickBtn()" color="primary" width="100%">
            <slot name="btn-text">稍後再分級</slot>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn depressed @click="clickBtn(true)" color="primary" width="100%" :disabled="!selectedGroup">
            <slot name="btn-text">確認</slot>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    
  },
  props: {
    
  },
  data: () => ({
    list: [],
    selectedGroup: null
  }),
  computed: {
    storeName() {
      return this.$route.query.storeName;
    },
    storeId() {
      return this.$route.query.storeId;
    },
    providerId() {
      return this.$route.query.providerId;
    },
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const result = await this.$api.collection.vipApi.read(this.providerId)
        console.log('fetchData:', result)
        this.list = result
        if (this.list.length > 0) this.selectedGroup = this.list[0]
      } catch (error) {
        console.error(error)
      }
    },
    async clickBtn(isConfirm) {
      try {
        console.log('isConfirm:', isConfirm)
        if (isConfirm) {
          this.$root.gaLogEvent('供應商_點擊_歸屬客戶分級')
          const result = await this.$api.collection.vipApi.addStore(this.providerId, this.storeId, { vip_group_id: this.selectedGroup.id })
          this.$snotify.success('新增成功')
        }
        this.$router.push({
          name: 'client-status-accept',
          query: {
            name: this.storeName,
            storeId: this.storeId,
            providerId: this.providerId,
          },
        });
      } catch (error) {
        console.error(error)
        this.$snotify.error('新增失敗')
      }
    },
  },
};
</script>